import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import 'common/src/assets/css/google-font.css';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/SaasModern/sassModern.style';

import Navbar from '../containers/SaasModern/Navbar';
import FaqSection from '../containers/SaasModern/Faq';
import Footer from '../containers/SaasModern/Footer';
import SEO from '../components/seo';

export default () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO title="Circulo Cuadro" />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky
            enabled={false}
            top={0}
            innerZ={9999}
            className="sticky-nav-active"
            activeClass="sticky-nav-active"
          >
            <DrawerProvider>
              <Navbar staticLink={true} />
            </DrawerProvider>
          </Sticky>
          <div style={{ height: '80px' }}></div>
          <FaqSection />
          <div style={{ height: '50px' }}></div>
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
